import React, { useReducer, useEffect, lazy, Suspense } from 'react';
import { initialState, Reducer } from '../../reducer';
import Preloader from '../../Components/Preloader';
import { App, SkipLink } from './style';

const TopBar = lazy(() => import('../../Components/TopBar/TopBar'));
const StartApp = lazy(() => import('../../Parts/StartApp/StartApp'));
const BottomBar = lazy(() => import('../../Components/BottomBar/BottomBar'));

const CustomWindow = lazy(() => import('../../Parts/CustomWindow/CustomWindow'));
const Staff = lazy(() => import('../../Parts/Staff/Staff'));

const ProductGallery = lazy(() => import('../../Parts/ProductGallery/ProductGallery'));
const Bilbords = lazy(() => import('../../Parts/BilbordsStatic/BilbordsStatic'));
const Clients = lazy(() => import('../../Parts/Clients/Clients'));

function AppMain() {
    const [state, dispatch] = useReducer(Reducer, initialState);
    const [massage, UseMassage] = React.useState(false);

    const scrollWindow = () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    };

    const closePreviewClickEsc = (e) => {
        if (e.keyCode === 27) {
            dispatch({ type: 'close-tab' });
        } else {
            return;
        }
    };

    const ClickOnScroll = (id) => {
        document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    };

    const closePreview = () => dispatch({ type: 'close-tab' });

    const onClickOpenTabPreview = (category, prevImg, imageList) => {
        dispatch({ type: 'open-tab', payload: { category, prevImg, imageList } });
    };

    const ShowMessage = () => {
        UseMassage(true);
        setTimeout(() => UseMassage(false), 3000);
    };

    useEffect(() => {
        document.addEventListener('scroll', scrollWindow);
        document.addEventListener('keydown', closePreviewClickEsc);

        return () => {
            document.removeEventListener('scroll', scrollWindow);
            document.removeEventListener('keydown', closePreviewClickEsc);
        };
    }, [state.showPreview]);

    return (
        <App>
            <SkipLink href='#maincontent'>Skip to main</SkipLink>

            <Suspense fallback={<Preloader />}>
                <TopBar onClickShowTab={onClickOpenTabPreview} category={state.category} massage={massage} />
            </Suspense>

            <Suspense fallback={<Preloader />}>
                <CustomWindow
                    itemCategory={state.category}
                    showPreview={state.showPreview}
                    prevImage={state.prevImg}
                    closePreview={closePreview}
                    sendCloseTab={closePreview}
                    closePreviewClickEsc={closePreviewClickEsc}
                    imageList={state.imageList}
                    ShowMessage={ShowMessage}
                />
            </Suspense>

            <Suspense fallback={<Preloader />}>
                <StartApp ClickOnScroll={ClickOnScroll} />
            </Suspense>

            <Suspense fallback={<Preloader />}>
                <Staff onClickOpenTabPreview={onClickOpenTabPreview} partName='staff' />
            </Suspense>

            <Suspense fallback={<Preloader />}>
                <ProductGallery
                    partName='viveski'
                    galleryName='ВЫВЕСКИ'
                    onClickOpenTabPreview={onClickOpenTabPreview}
                    bgGray
                    blue
                />
            </Suspense>

            <Suspense fallback={<Preloader />}>
                <ProductGallery
                    galleryName='БАННЕРА'
                    onClickOpenTabPreview={onClickOpenTabPreview}
                    partName='banners'
                />
            </Suspense>

            <Suspense fallback={<Preloader />}>
                <ProductGallery
                    galleryName='СТЕНДЫ / ТАБЛИЧКИ / ПЕЧАТИ'
                    onClickOpenTabPreview={onClickOpenTabPreview}
                    partName='stendy'
                    bgGray
                    blue
                />
            </Suspense>

            <Suspense fallback={<Preloader />}>
                <ProductGallery
                    galleryName='ПОЛИГРАФИЯ'
                    onClickOpenTabPreview={onClickOpenTabPreview}
                    partName='poly'
                />
            </Suspense>

            <Suspense fallback={<Preloader />}>
                <Bilbords onClickOpenTabPreview={onClickOpenTabPreview} partName='bilbords' />
            </Suspense>

            <Suspense fallback={<Preloader />}>
                <Clients />{' '}
            </Suspense>

            <Suspense fallback={<Preloader />}>
                <BottomBar />
            </Suspense>
        </App>
    );
}

export default AppMain;
