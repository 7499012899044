import React from 'react';
import AppMain from './Layout/AppMain';
import db, { FirebaseContext } from './firebase';
import { loadReCaptcha } from 'react-recaptcha-google';

const App = () => {
    const [lists, Uselists] = React.useState([]);
    React.useEffect(() => {
        db.ref()
            .once('value')
            .then((snap) => {
                Uselists(snap.val());
            });
        loadReCaptcha();
    }, []);

    return (
        <FirebaseContext.Provider value={lists}>
            <AppMain />;
        </FirebaseContext.Provider>
    );
};

export default React.memo(App);
